import React, { FC } from "react";

type Props = {
  color: string;
  width: number;
  height: number;
};

const ArrowOutwardIcon: FC<Props> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 1.5H10.5M10.5 1.5V9.75M10.5 1.5L1.5 10.5"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowOutwardIcon;
