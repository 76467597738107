/**
 * @generated SignedSource<<0f298127fada0d9bde0a5305b9438802>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NamespaceIcon_data$data = {
  readonly avatar: string;
  readonly displayName: string | null | undefined;
  readonly " $fragmentType": "NamespaceIcon_data";
};
export type NamespaceIcon_data$key = {
  readonly " $data"?: NamespaceIcon_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"NamespaceIcon_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NamespaceIcon_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "Namespace",
  "abstractKey": null
};

(node as any).hash = "6a35e29b000a9fa9b3775cddc33c9dfd";

export default node;
