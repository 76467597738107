/**
 * @generated SignedSource<<700ad57fe35ee21223f9b527e411b400>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchResults_data$data = ReadonlyArray<{
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"DeployAppSearchItem_details" | "NamespaceSearchItem_details" | "PackageSearchItem_details" | "UserSearchItem_details">;
  readonly " $fragmentType": "SearchResults_data";
}>;
export type SearchResults_data$key = ReadonlyArray<{
  readonly " $data"?: SearchResults_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResults_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SearchResults_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserSearchItem_details"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PackageSearchItem_details"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NamespaceSearchItem_details"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeployAppSearchItem_details"
    }
  ],
  "type": "SearchResult",
  "abstractKey": "__isSearchResult"
};

(node as any).hash = "9b665b6ee6b78ca72cc88d12918cd3e4";

export default node;
