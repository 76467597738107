import React from "react";

import { cn } from "@libs/helper";

import styles from "./index.module.css";

interface PageSectionProps {
  children: React.ReactNode;
  classNameForWrapper?: string;
  className?: string;
  withTopBorder?: boolean;
  withGradientBackground?: boolean;
}
const PageSection: React.FC<PageSectionProps> = ({
  children,
  className,
  classNameForWrapper,
  withTopBorder = false,
  withGradientBackground = false,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      className={cn(
        styles.wrapper,
        withTopBorder && styles.withTopBorder,
        withGradientBackground && styles.withGradientBackground,
        classNameForWrapper
      )}
    >
      <div className={cn(styles.content, className)}>{children}</div>
    </div>
  );
};

export default PageSection;
