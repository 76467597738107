import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const SearchDownload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("", props?.className))}
    >
      <g
        clipPath="url(#prefix__clip0_6_7430)"
        stroke="#0F0518"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.234 15.56V2M14.995 9.477l-5.76 6.082-5.76-6.082M1.232 21h16" />
      </g>
      <defs>
        <clipPath id="prefix__clip0_6_7430">
          <path fill="#fff" transform="translate(.232 .5)" d="M0 0h18v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchDownload;
