import { GetStartedList, GuidesList, SdkList } from "@layout/Header";
import Image from "next/image";
import Link from "next/link";
import React, { MouseEventHandler, useState } from "react";

import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";
// @ts-ignore
import book from "@assets/icons/book.svg?url";
// @ts-ignore
import cli from "@assets/icons/cli.svg?url";
// @ts-ignore
import leftArrow from "@assets/icons/leftArrow.svg?url";
import { Transition } from "@headlessui/react";
import { WASMER_DOCS_URL } from "@utils/constants";

interface DevelopersNavbarItemHoverContentProps {
  show: boolean;
}

const DevelopersNavbarItemHoverContent: React.FC<DevelopersNavbarItemHoverContentProps> = ({ show }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
      setIsHovered(true);
    },
    handleMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
      setIsHovered(false);
    };

  return (
    <Transition
      show={show || isHovered}
      //   show={true}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      enter="transition-opacity duration-[400ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute top-[120%] left-1/2 z-20 max-h-[85vh] w-max -translate-x-1/2 overflow-y-auto rounded-xl border border-[#cecdd2] bg-[#FBFBFC] shadow-xl">
        <div className="px-6 py-5 sm:px-7">
          <div className="hidden items-center pt-4 pb-5 lg:flex">
            <Image src={book} alt="book" width={30} height={28} />
            <Link target="_blank" href={WASMER_DOCS_URL}>
              <h5 className="ml-4 flex font-semibold">
                Documentation{" "}
                <span className="ml-2">
                  <ArrowOutwardIcon color="#0e061a" width={8} height={8} />
                </span>
              </h5>
            </Link>
          </div>
          <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
            <Image src={leftArrow} alt="arrow" width={6} height={11} className="mr-2 rotate-180" /> Developers
          </div>
          <div className="lg:flex">
            <div className="w-[130px]">
              <p className="text-dark-gray whitespace-nowrap pb-3 font-medium">Learn more</p>
              {GetStartedList?.map((document, index) => (
                <Link target="_blank" href={document.href} className="block pb-3 font-semibold" key={index}>
                  {document.title}
                </Link>
              ))}
            </div>
            <div>
              <p className="text-dark-gray font-medium">Get Started</p>
              {GuidesList?.map((guides, index) => (
                <Link target="_blank" href={guides?.href} key={index} className="block pt-3 font-semibold">
                  {guides?.guide}
                </Link>
              ))}
            </div>
          </div>
          <div className="hidden lg:block">
            <Link
              target="_blank"
              href="https://docs.wasmer.io/runtime/cli"
              className="flex items-center border-y border-[#E5E5E8] py-5"
            >
              <Image src={cli} alt="book" width={32} height={32} />
              <h6 className="ml-3 text-new-base font-semibold">CLI</h6>
            </Link>
            <p className="text-dark-gray pt-5 pb-2">Tools</p>
            <Link href="https://marketplace.visualstudio.com/items?itemName=dtsvet.vscode-wasm">
              <h6 className="font-semibold">Visual Studio Code Extension</h6>
            </Link>
          </div>
        </div>
        <div className="bg-dark-black m-1 hidden rounded-xl px-6 py-5 shadow-xl lg:block">
          <h6 className="font-semibold text-[#85808A]">SDK&apos;S</h6>
          <div className="flex flex-wrap items-center gap-6 pt-3">
            {SdkList?.map((sdk, index) => (
              <Link target="_blank" href={sdk?.href} key={index}>
                <h5 className="text-light-white font-semibold">{sdk?.title}</h5>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default DevelopersNavbarItemHoverContent;
