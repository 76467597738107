// import { NamespaceSearchItem_details$key } from "@generated/NamespaceSearchItem_details.graphql";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
// import { graphql, useFragment } from "react-relay";
import { twMerge } from "tailwind-merge";

import Collaborators from "@assets/Collaborators";
import PackageNew from "@assets/PackageNew";
import NamespaceIcon from "@components/NamespaceIcon";
import { NamespaceSearchItem_details$key } from "@generated/NamespaceSearchItem_details.graphql";
import { Combobox } from "@headlessui/react";

import SearchCounterIcon from "./SearchCounterIcon";
import { SearchInputValueType } from "./SearchInput";

const NamespaceSearchItemFragment = graphql`
  fragment NamespaceSearchItem_details on Namespace {
    name
    description
    collaborators {
      totalCount
    }
    packages {
      totalCount
    }
    ...NamespaceIcon_data
  }
`;

type NamespaceSearchItemProps = {
  fragmentRef: NamespaceSearchItem_details$key;
  className?: string;
};

const NamespaceSearchItem: React.FC<NamespaceSearchItemProps> = ({ fragmentRef, className }) => {
  const data = useFragment(NamespaceSearchItemFragment, fragmentRef),
    { description, name, collaborators, packages } = data;
  const router = useRouter();
  const handleRedirection = () => {
    router.push(`/${name}`);
  };
  const optionValue = {
      type: SearchInputValueType.link,
      value: name,
    },
    packagesTotalCount = packages?.totalCount,
    collaboratorsTotalCount = collaborators?.totalCount;

  return (
    <Combobox.Option
      value={optionValue}
      className={twMerge(clsx("flex w-full items-center justify-center gap-3", className))}
      onClick={handleRedirection}
    >
      <NamespaceIcon fragmentRef={data} />
      <div className="flex w-full flex-1 items-center justify-between">
        <div className="flex flex-col">
          <h6 className="flex items-center justify-start gap-[3px] text-[16px] font-semibold text-[#0F0518]">{name}</h6>
          <p className="text-[13px] font-[400] text-[#0F0518]/50">{description}</p>
        </div>
        <div className="hidden items-center justify-center gap-5 md:flex">
          <SearchCounterIcon
            icon={<Collaborators className="h-[22px] w-[22px]" />}
            count={collaboratorsTotalCount || 0}
          />
          <SearchCounterIcon icon={<PackageNew className="h-[22px] w-[22px]" />} count={packagesTotalCount || 0} />
        </div>
      </div>
    </Combobox.Option>
  );
};

export default NamespaceSearchItem;
