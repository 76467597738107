import React, { FC, MouseEventHandler } from "react";

type Props = {
  title: string;
  classname?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  [key: string]: any;
};

const Button: FC<Props> = ({ title, classname, onClick, ...restProps }) => {
  return (
    <button
      {...restProps}
      className={`bg-dark-black bg-hover-gradient text-light-white rounded-full py-1 px-3 text-new-base font-bold md:py-1.5 md:px-4 ${classname}`}
      {...(onClick ? { onClick } : {})}
    >
      {title}
    </button>
  );
};

export default Button;
