import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";
import { graphql, useFragment } from "react-relay";
import { twMerge } from "tailwind-merge";

import SearchDownload from "@assets/SearchDownload";
import SearchHeart from "@assets/SearchHeart";
import { PackageSearchItem_details$key } from "@generated/PackageSearchItem_details.graphql";
import { Combobox } from "@headlessui/react";

import SearchCounterIcon from "./SearchCounterIcon";
import { SearchInputValueType } from "./SearchInput";

const PackageSearchItemFragment = graphql`
  fragment PackageSearchItem_details on PackageVersion {
    id
    package {
      displayName
      icon
      downloadsCount
      likersCount
    }
    description
  }
`;

type PackageSearchItemProps = {
  fragmentRef: PackageSearchItem_details$key;
  className?: string;
};

const PackageSearchItem: React.FC<PackageSearchItemProps> = ({ className, fragmentRef }) => {
  const router = useRouter();
  const { description, package: pkg } = useFragment(PackageSearchItemFragment, fragmentRef);
  const handleRedirection = () => {
    router.push(`/${pkg.displayName}`);
  };
  const [pkgOwner, pkgName] = pkg?.displayName?.split("/");
  const optionValue = {
    type: SearchInputValueType.link,
    value: pkg.displayName,
  };
  return (
    <Combobox.Option
      value={optionValue}
      className={twMerge(clsx("flex w-full items-start justify-center gap-3", className))}
      onClick={handleRedirection}
    >
      <Image
        src={pkg.icon}
        alt="package icon"
        width={40}
        height={40}
        className="col-start-1 row-start-1 max-h-[40px] rounded-[8px] border border-[#0F0518]/20"
        crossOrigin="anonymous"
      />
      <div className="flex w-full flex-1 items-center justify-between">
        <div className="flex flex-col">
          <h6 className="flex flex-wrap items-center justify-start gap-[3px] text-[16px] font-semibold text-[#0F0518]">
            {pkgOwner}
            <span className="font-light">/</span>
            {pkgName}
          </h6>
          <p className="text-[13px] font-[400] text-[#0F0518]/50">{description}</p>
        </div>
        <div className="hidden items-center justify-center gap-5 md:flex">
          <SearchCounterIcon icon={<SearchHeart className="h-[22px] w-[22px]" />} count={pkg?.likersCount!} />
          <SearchCounterIcon
            icon={<SearchDownload className="hidden h-[22px] w-[18px] md:block" />}
            count={pkg?.downloadsCount!}
          />
        </div>
      </div>
    </Combobox.Option>
  );
};

export default PackageSearchItem;
