import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

interface SearchHeartProps {
  className?: string;
  icon: React.ReactNode;
  count: number | string;
}
const SearchCounterIcon: React.FC<SearchHeartProps> = ({ count, icon, className }) => {
  return (
    <div className={twMerge(clsx("flex items-center justify-center gap-2 opacity-50", className))}>
      {icon}
      <p className="text-right text-base font-semibold leading-snug text-[#0F0518]">{count}</p>
    </div>
  );
};

export default SearchCounterIcon;
