import Image from "next/image";
import React from "react";

// @ts-ignore
import plus from "@assets/icons/plus.svg?url";

const SubScribeNewsletter = () => {
  return (
    <div className="py-28 xxlg:py-36">
      <h5 className="mx-4 text-center text-[36px] font-medium leading-[50px] sm:mx-0 md:text-[40px] md:leading-[56px] lg:text-[48px] lg:leading-[67px]">
        Subscribe to our newsletter
      </h5>
      <p className="mx-10 mb-8 mt-4 text-center text-new-xl font-normal text-[#534C5B] md:mx-auto md:max-w-[655px] lg:text-[22px]">
        A newsletter for developers covering techniques, technical guides, and the latest product innovations coming
        from Wasmer.
      </p>
      <div className="relative mx-10 sm:mx-auto sm:w-[480px]">
        <input
          className="w-full rounded-full border-none bg-[#E4E5EB] py-[17px] pl-6 pr-16 text-new-base font-normal text-[#797481] outline-none placeholder:text-[#797481] hover:bg-[#0f051812] sm:pr-40"
          type="email"
          placeholder="Your email address"
        />
        <button className="bg-dark-black text-light-white absolute top-2.5 right-2.5 flex items-center rounded-full py-3 px-4 font-zeitung text-new-base sm:py-1.5">
          <Image src={plus} alt="add" width={12} height={12} className="sm:mr-3" />
          <span className="hidden sm:inline-block"> Subsribe </span>
        </button>
      </div>
    </div>
  );
};

export default SubScribeNewsletter;
