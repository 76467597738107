export const BLOG_POST_TAGS_QUERY_PARAM = "tags";
export const DEPLOY_PUBLIC_ALPHA_WAITLIST_NAME = "deploy-public-alpha";
export const DEPLOY_CURRENT_STAGE = "alpha";
export const DEPLOYMENT_SUCCESS_MESSAGE = "The application has been successfully deployed";
export const SETUP_PAYMENT_URL = "/payment/setup";
export const TOS_MARKDOWN = `

This Wasmer Terms of Service Agreement is made and entered between 'Wasmer Inc'
(known as Wasmer henceforth) and the Customer agreeing to these terms.

The effective date of this Agreement is as of the date Customer clicks or types
accept to the Agreement. If you are accepting on behalf of a Customer, you must:

-   have full legal authority to accept this agreement on behalf of the
    Customer;

    -   have read and understand this Agreement; and

    -   accept on behalf of Customer, the terms of this Agreement. If you do not
        have the legal authority to accept on behalf of the Customer, please do
        not click or type accept. This Agreement governs Customer's access to
        and use of Wasmer Services.

0. Definitions
--------------

-   "Business Day" means any day during the Hours of Operation.

    -   "Feature Request" means a Request by a Contact to incorporate a new
        feature or enhance an existing feature of the Services that is currently
        not available as part of the existing Services.

    -   "Maintenance" means maintenance work that is performed on hardware or
        software delivering by the Wasmer Platform.

    -   "Request" means a request from a Customer to Wasmer for technical
        support to resolve a question or problem report regarding the Wasmer
        Platform.

    -   "Platform Unusable" is any situation where Customer, adhering to
        published technical guidelines for and documented correct usage of the
        Services, is unable to access or use the Platform for the majority of
        its Customer End Users for a period of time greater than fifteen (15)
        minutes.

    -   "IaaS" means Infrastructure as a Service.

    -   "SaaS" means Software as a Service.

 

1. Provision of Services.
-------------------------

### 1.1. Services Use.

Subject to this Agreement, during the Term, Customers join Wasmer and may use
Services provided by the Wasmer Community. In the Wasmer Community, the Customer
can play two roles; Buyer or Seller. The Buyer can purchase services provided by
Sellers via the 'Wasmer Platform' (referred to as 'Platform' henceforth). The
Customer may not sublicense or transfer these rights to another entity. Wasmer
is not responsible for the quality, availability and/or integrity of the
services provided by Sellers. Sellers must provide their service terms and
conditions for Buyers to accept during the purchasing process.

### 1.2. Data Center Facilities and Data Location.

Data center facilities offered via Wasmer are provided by different Sellers.
These Data Center facilities are in different locations. Wasmer is not
responsible for the accuracy of the claims made by Sellers. It is the Buyer's
responsibility to verify the accuracy of those claims. Sellers found making
misleading or incorrect claims will have this Agreement terminated immediately.

### 1.3. Accounts.

The Customer must have an Account and a 2nd factor token to use Wasmer. The
Customer is responsible for the information it provides to create the Account,
the security of the Token and passwords for the Account, and for any use of its
Account and the Token. The Customer must not impersonate another user or share
their password to anyone under any circumstance. The Customer is also
responsible for keeping their information provided to Wasmer up to date.

### 1.4. Modifications.

-   To the Services. Wasmer may update the Platform from time to time. If Wasmer
    makes a material change to the Platform, Wasmer will inform the Customer via
    email and via the following web-site
    <http://wasmer.io/announcements/releases>.

    -   To the Agreement. Wasmer may make changes to this Agreement from time to
        time. Material changes to the Agreement will become effective as soon as
        they are deployed live on this web-site <http://wasmer.io/legal/terms>.
        If Customer does not agree to the revised Agreement, the Customer may
        terminate the Agreement and stop using the service.

 

2. Payment Terms.
-----------------

### 2.1. Paying for a Service.

The Marketplace operates on Wasmer Credits in a 'Pay as you Use' fashion. Buyers
must purchase Wasmer Credits to acquire services from Sellers. Wasmer Credits
are periodically deducted from the Buyer's account as per the rate card agreed
to by the Buyer when they accepted the contract for the services. It is the
Buyer's responsibility to ensure that their account is sufficiently funded for
their intended purpose.

### 2.2. Contract Grace Period.

If the Buyer's account does not have enough Wasmer Credits to continue using the
service then the Buyer will enter the 'Grace Period', this period of acceptable
default is defined by the Seller's rate card and agreed during contract
settlement. During this time, 'best automated efforts' will be made to contact
the Buyer while their account continues to go into a negative balance as
services are used, after this grace period has been exceeded then the service
and its contract will be terminated. At a later time, should a Buyer top up his
account, these deferred transactions will be automatically transferred to the
Seller in an order defined by Wasmer. It is the Seller's responsibility to bare
the costs of the 'Grace Period' should the Buyer terminate their account before
topping up their negative balance.

### 2.3. Receiving Payment for Services rendered.

Sellers who provide services to the Community receives payment (minus Wasmer
Fees) of Wasmer Credits if their services are used by Buyers. The Platform
tracks the service usage and ensures that Buyer pays for what they use. Wasmer
endeavours to minimize the risk of non-payment for services rendered, but it
cannot eliminate that risk. Wasmer is not responsible for any non-payment for
services rendered owned by the Buyer to the Seller.

### 2.4. Taxes.

The Platform distributes both sales and tax revenue (minus Wasmer's fees)
generated by the Seller's services to the Seller. It is the Seller's
responsibility to manage and pay relevant taxes applicable to their tax
jurisdiction. The Platform provides the information for Sellers to price their
services and collect taxes from Buyers as an inclusive advertised price.

### 2.5. Wasmer Credits.

Wasmer Credit is the only valid payment medium acceptable on the Platform.
Wasmer Credits are valued at 1 USD per 1 Wasmer Credit. Wasmer reserves the
right to change the valuation at any time. Customers will be notified of any
changes to Wasmer Credit values via the communication details provided by the
Customer. Subsequent to adjustment, any existing Wasmer Credit balances will be
exchangeable at the new rate while the balances themselves remain unchanged.

 

3. Customer Obligations.
------------------------

### 3.1. Compliance.

Customer is solely responsible for its Applications, End Users and Data and for
making sure its Applications, End Users and Data comply with Wasmer's Acceptable
Use Policy.

### 3.2. Privacy.

It is the Customer's responsibility to obtain and maintain any required consents
necessary to permit the processing of the Customer's End User Data. It is the
Customer's responsibility to ensure compliance to the End User Privacy
Legislation of the jurisdiction that the Customer operates in.  

### 3.3. Restrictions.

Customer will not, and will not allow third parties with any relationship with
the Customer to:

-   use the Services for High Risk Activities;

    -   maliciously engage in actions that access the Platform in a manner
        intended to avoid incurring Fees;

    -   any other violation of terms specified in this Terms and Conditions

### 3.4. Documentation.

Wasmer may provide Documentation for Customer's use of the Services. The
Documentation may specify restrictions on how Customer Applications may be built
or the Services may be used. The Customer will comply with any such restrictions
specified.

### 3.5. Seller Obligations.

Customers who sell services (IaaS or SaaS) have the following obligations

-   State all relevant details (e.g. location of service, terms and conditions,
    SLAs, pricing details) of your service truthfully up front to prospective
    buyers

    -   Fulfill the service described in the previous statement promptly and
        consistently

    -   Fulfill minimum availability SLA specified by Wasmer. If these standards
        are not met for a period of time, the pro-rata charges for that period
        will be automatically refunded to the Buyer by the Platform.

### 3.6 Multiple Accounts.

Customers are restricted from registering multiple Accounts with the same
billing details without first notifying Wasmer. Otherwise, Wasmer shall have the
right to automatically flag such Accounts as fraudulent or abusive, and Wasmer
may, without notification to the Customer, suspend the service of such Account
or any other Account used by such Customers. Wasmer also reserves the right to
terminate a Customer's Account if it is targeted by malicious activity from
other parties.

### 3.7 Legal Compliance. 

Customers must comply with, and refrain from violations of, any right of any
other person, entity, law, or contractual duty, accordingly to the Customer's
applicable legal jurisdiction and including without limitation those laws
forbidding:

-   distribution of child pornography,

    -   forgery, identity theft, misdirection or interference with electronic
        communications,

    -   invasion of privacy,

    -   unlawful sending of commercial electronic messages or other marketing or
        electronic communications,

    -   collection of excessive user data from children, or other improper data
        collection activities,

    -   securities violations, wire fraud, money laundering, or terrorist
        activities, or

    -   false advertising, propagating or profiting from frauds and unfair
        schemes.

Customers will also comply with the laws and regulations governing use of the
Services, including but not limited to:

-   disclosure requirements, including those regarding notification of security
    breaches,

    -   records maintenance for regulated industries, and

    -   financial institution safeguards.

### 3.8. Agreed Use of Allotted Network Resources

The Customer shall not use any method to circumvent the provisions of these
Terms of Service, or to obtain Services in excess of those contracted with
Wasmer or Sellers. Customers shall use only those IP addresses that are assigned
to them by Wasmer, and shall not use any IP addresses outside of their assigned
range. Customers shall not use any mechanism to exceed the amount of resources
assigned to them through the Platform, or to conceal such activities.

### 3.9. Injurious Code.

The Customer may not use the Platform to distribute, receive communications or
data gleaned from, or execute any action directed by any type of injurious code,
including but not limited to:

-   trojans,

    -   key loggers,

    -   viruses,

    -   malware,

    -   botnets,

    -   denial of service attacks,

    -   flood or mail bombs,

    -   logic bombs, or

    -   other actions which Wasmer reserves the sole right to determine to be
        malicious in intent.

### 3.10. Email Violations.

Customers shall not send bulk email utilizing their resources on the Platform
unless they maintain a double-authorized list of subscribed members including IP
addresses and relevant contact information, along with following guidelines for
including removal links with all sent emails according to the such legislation.
The Customer shall comply with all laws regarding the sending of commercial
electronic messages or other marketing or electronic communications. The
Customer is forbidden from taking any action that would result in their IP
addresses, or any IP address associated with Wasmer or other Customers, being
placed on the Spamhaus.org blacklist. Wasmer reserves the sole right to
determine whether an email violation has occurred.

### 3.11. Invasion of Privacy, Defamation, or Harassment.

The Customer may not use the Services in a manner that would violate the privacy
rights of any person, or to publish or republish defamatory or libelous
statements, or to harass or embarrass, which shall be determined in Wasmer's
sole discretion.

### 3.12. Violation of Copyright, Trademark, Patent or Trade Secret

The Customer may not use the Platform in violation of the copyrights,
trademarks, patents or trade secrets of third parties, nor shall they utilize
the Services to publish such materials in a manner that would expose them to
public view in violation of the law. Provisions of applicable international
trademark, copyright, patent or other intellectual property laws apply to issues
presented by allegations of copyright violations by third parties. Wasmer will
terminate the accounts of repeat violators. If a third party believes that a
Customer of Wasmer is violating its intellectual property rights, it should
notify us by email at <abuse@wasmer.io>. A notification should include
information reasonably sufficient to permit Wasmer to locate the allegedly
infringing material, such as the IP address or URL of the specific online
location where the alleged infringement is occurring. Wasmer cannot determine
whether something is being used legally or not.

### 3.13. Export.

The Customer shall comply with all applicable export and import control laws and
regulations, and, in particular, the Customer shall not utilize the Platform to
export or re-export data or software without all required government licenses.
The Customer assumes full legal responsibility for any access and use of the
Platform, with full understanding that the same may constitute export of
technology and technical data that may implicate export regulations and/or
require export license. Should such a license be required, it shall be
Customer's responsibility to obtain, at Customer's sole cost and expense, and in
the event of any breach of this duty resulting in legal claims against Wasmer,
The Customer shall defend, indemnify and hold Wasmer harmless from all claims
and damages arising therefrom.

### 3.17. Acts of Sub-Users

The Customer is responsible for the acts of others utilizing their access to the
Platform, and will be held responsible for violations by their sub-users or
persons who gain access to the Platform using the Customer's access codes. Any
activity that a Customer is prohibited from performing by these Terms of
Services is equally prohibited to anyone using the access to the Platform of the
Customer.

### 3.18. Access Code Protection.

The Customer shall utilize proper security protocols, such as setting strong
passwords and access control mechanisms, safeguarding access to all logins and
passwords, and verifying the trustworthiness of persons who are entrusted with
account access information.

### 3.19. Notification Regarding these Terms of Service.

The Customer shall notify all persons who receive access to the Platform of the
provisions of these Terms of Service, and shall inform them that the terms of
these Terms of Service are binding upon them.

### 3.20. Remedial Action

The Customer shall notify Wasmer if and when they learn of any security breaches
regarding the Platform, and shall aid in any investigation or legal action that
is taken by authorities and/or Wasmer to resolve the security breach.

 

4. Suspension and Removals.
---------------------------

### 4.1. Suspension/Removals.

If the Customer becomes aware that any of its Applications, or End Users
violates the Wasmer AUP, the Customer will immediately suspend or remove the
Application or End User (as applicable). If Customer fails to suspend or remove
as noted in the prior sentence, Wasmer may specifically request that Customer do
so. If Customer fails to comply with Wasmer's request to do so within 24 hours,
then Wasmer may disable the offending Virtual Node(s) unilaterally, Wasmer may
also disable the Customer's Account.

### 4.2. Emergency Security Issues.

If there is an Emergency Security Issue, then Wasmer may unilaterally disable
the offending Virtual Node(s). If Wasmer suspends a Customer's Virtual Node, for
any reason, without prior notice to Customer, at Customer's request, Wasmer will
provide Customer with the reason for the suspension.

 

5. Intellectual Property Rights; Use of Customer Data.
------------------------------------------------------

### 5.1. Intellectual Property Rights.

The Customer owns all Intellectual Property Rights to their Data and
Application. Wasmer owns all Intellectual Property Rights in the Wasmer Platform
and Software.

### 5.2. Wasmer’s Data Access Rights.

Wasmer reserves the right to access, read, preserve, and disclose any
information that is necessary to

-   satisfy any applicable law, regulation, legal process or governmental
    request,  

    -   enforce these Terms of Service, including investigation of potential
        violations hereof,

    -   detect, prevent, or otherwise address fraud, security or technical
        issues,

    -   respond to user support requests, or (v) protect the rights, property or
        safety of us, our users and the public.

 

6. Technical Support Services
-----------------------------

### 6.1. By Customer.

The Customer is responsible for technical support of its Virtual Nodes, Virtual
Networks, Virtual Disks Applications and Data. Customers acting as Sellers are
responsible for their Wasmer service offering (Services, Applications or
Wasmer Nodes).

### 6.2. By Wasmer.

Wasmer will provide Technical Support Services to the Customer during the Term
in accordance with the Wasmer Technical Support Services Guidelines.

 

7. Deprecation of Services
--------------------------

### 7.1. Discontinuance of Services.

Wasmer may discontinue any Services or any portion or feature for any reason at
any time without liability to Customer.

### 7.2. Deprecation Policy.

Wasmer will announce if it intends to discontinue or make backwards incompatible
changes to the Services specified at
<http://wasmer.io/announcements/deprecation>. Wasmer will use commercially
reasonable efforts to continue to operate those Services versions and features
identified without these changes for at least one year after that announcement,
unless:

-   required by law or third party relationship, or

    -   doing so could create a security risk or substantial economic or
        material technical burden.

 

8. Term and Termination.
------------------------

### 8.1. Agreement Term.

The “Term” of this Agreement will begin on the Effective Date and continue until
the Agreement is terminated as set forth in Section 9.2 of this Agreement.

### 8.2. Termination for Breach.

Either party may terminate this Agreement for breach if:

-   the other party is in material breach of the Agreement and fails to cure
    that breach within 10 days after receipt of written notice;

    -   the other party ceases its business operations or becomes subject to
        insolvency proceedings and the proceedings are not dismissed within 90
        days; or

    -   the other party is in material breach of this Agreement more than two
        times notwithstanding any cure of such breaches.

### 8.3. Termination for Inactivity.

Wasmer reserves the right to terminate the Services for inactivity, if, for a
period exceeding 180 days, Customer:

-   has failed to access the Admin Console;

    -   an account has no active Wasmer Node or Virtual Node, Virtual Network or
        Virtual Storage

### 8.4. Termination for Convenience.

The Customer may stop using the Platform at any time. Customer may terminate
this Agreement at any time. Upon termination, the Customer must cease use of the
Platform.

### 8.5. Effect of Termination.

If the Agreement is terminated, then:

-   the rights granted by one party to the other will immediately cease;

    -   all Fees owed by Customer to Sellers are immediately due;

    -   the Customer will delete the Software, any Application, Instance, and
        any Customer Data; and

    -   upon request, each party will use commercially reasonable efforts to
        return or destroy all Confidential Information of the other party.

 

9. Publicity.
-------------

9.1. Customer is permitted to state publicly that it is a customer of the
Platform, consistent with the Trademark Guidelines. Wasmer may include the
Customer's name in a list of Wasmer customers, online or in promotional
materials. Wasmer may also verbally reference Customer as a customer of the
Services.

9.2. Comments, suggestions or materials sent or transmitted to Wasmer
(collectively "Feedback"), shall be deemed to be non-confidential. Wasmer shall
have no obligation of any kind with respect to such Feedback and shall be free
to use and distribute the Feedback to others without limitation, including, but
not limited to developing and marketing products incorporating such Feedback.
Wasmer reserves the right to publish or use any responses, questions or comments
emailed to Wasmer for promotional or other purposes without any further
permission, notice or payment of any kind to the sender. All such submissions
will be the property of Wasmer.

 

10. Representations and Warranties.
-----------------------------------

Each party represents and warrants that:

-   it has full power and authority to enter into the Agreement; and

    -   it will comply with all applicable laws and regulations when using the
        Platform.

 

11. Disclaimer.
---------------

Wasmer does not make any other warranty of any kind, whether express, implied,
statutory or otherwise, including warranties of merchantability, fitness for a
particular use and non-infringement. Wasmer is not responsible or liable for the
deletion of or failure to store any customer data and other communications
maintained or transmitted through use of the Platform. The Customer is solely
responsible for securing and backing up its application and data. Wasmer does
not guarantee that the operation of Platform and services offered by Sellers on
the Platform will be error free or uninterrupted. Neither the software nor the
services are designed, manufactured, or intended for high risk activities.

 

12. Limitation of Liability.
----------------------------

### 12.1. Limitation of Liability

In no event shall Wasmer, nor our directors, employees, partners or suppliers be
liable under contract, tort, strict liability, negligence or any other legal or
equitable theory with respect to the services

-   for any lost profits, data loss, cost of procurement of substitute goods or
    services, or special, indirect, incidental, punitive, compensatory or
    consequential damages of any kind whatsoever, substitute goods or services,

    -   for any bugs, viruses, trojans, malware, or

    -   for any direct damages in excess of fees paid to us for the particular
        services during the immediately previous one month period, even if
        Wasmer had been advised of, knew, or should have known, of the
        possibility thereof.

The Customer acknowledges that the fees paid reflect the allocation of risk set
forth in this agreement and that Wasmer would not enter into this agreement
without these limitations. The Customer hereby waives any and all claims against
Wasmer arising out of Customer's purchase or use of the services, or any conduct
of Wasmer's directors, officers, employees or representatives. The Customer's
sole remedy in case of dissatisfaction with the services shall be the
termination of use of the Wasmer Platform. In addition, the Customer agree that
Wasmer is not responsible for any data compiled by our services and that Wasmer
will not be liable, in any manner, as a result of the Customer's exposure to any
defamatory, libelous, threatening, unlawfully harassing, obscene or otherwise
unlawful content or data. In no event shall Wasmer, or any third party provider
of any component of the services or of any information delivered as part of the
services, be liable to the Customer and/or any party for any damages of any
kind, including but not limited to direct, indirect, special, exemplary,
punitive, consequential or similar damages arising out of or related to the
services, content, products, the use or inability to use this web-site, or any
linked web-site, including without limitation, lost profits, loss of use,
business interruption, or other economic losses, loss of programs or other data,
whether in an action of contract, negligence or other tortious action, even if
Wasmer is advised of the possibility of such damages, including liability
associated with any viruses which may infect the Customer's computer equipment.
Some jurisdictions limit or prohibit the foregoing limitations, and in such
jurisdictions the foregoing limitations shall be applied to the maximum extent
permitted by law.

### 12.2. Exceptions to Limitations.

These limitations of liability do not apply to breaches of confidentiality
obligations, violations of a party's Intellectual Property Rights by the other
party, indemnification obligations, or Customer's payment obligations.

 

13. Indemnification.
--------------------

### 13.1. Customer Indemnification of Wasmer.

The Customer shall defend, indemnify, and hold harmless Wasmer, our affiliates,
parents, subsidiaries, any related companies, licensor's and partners, and each
of our and their respective employees, officers, directors, agents, contractors,
directors, suppliers and representatives from all liabilities, claims, and
expenses, including reasonable attorneys' fees, that arise from or relate to the
Customer (or any third party using the Customer's account or identity in the
services) use or misuse of, or access to, the services, content, or otherwise
from the Customer's user content, violation of these terms of service or of any
law, or infringement of any intellectual property or other right of any person
or entity. Wasmer reserves the right to assume the exclusive defense and control
of any matter otherwise subject to indemnification by the Customer, in which
event the Customer will assist and cooperate with Wasmer in asserting any
available defenses.

### 13.2 Remedies.

If Wasmer reasonably believes the Services might infringe a third party's
Intellectual Property Rights, then Wasmer may, at its sole option and expense:

-   procure the right for Customer to continue using the Services;

    -   modify the Services to make them non-infringing without materially
        reducing their functionality; or

    -   replace the Services with a non-infringing, functionally equivalent
        alternative.

If Wasmer does not believe the remedies in Section 14.2 are commercially
reasonable, then Wasmer may suspend or terminate Customer's use of the impacted
Services.

 

14. Choice of Law, Venue, Consent to Email Service
--------------------------------------------------

Any claim arising shall be construed in accordance with the substantive and
procedural laws of the State of New South Wales, Australia. The Customer agree
that any dispute arising from or relating to the subject matter of these Terms
of Service shall be governed by the exclusive jurisdiction and venue of the
state and Federal courts of New South Wales, Australia. The Customer consents to
service of process via email provided by the Customer, and waives any
requirement or other judicial treaty requiring that legal process be translated
into any language other than English.

 

15. Dispute Resolution
----------------------

### 15.1. Dispute Resolution Process

The Customer and Wasmer agree to the following dispute resolution process. In
the event of any dispute arising out of:

-   the Web-site;

    -   this Agreement;

    -   the Platform;

    -   the breach, enforcement, interpretation, or validity of this Agreement;
        or

    -   any other dispute between the Customer and Wasmer,

The party asserting the dispute shall first try in good faith to settle such
Dispute by providing written notice to the other party by email describing the
facts and circumstances (including any supporting documentation) of the Dispute
and allowing the receiving party 90 days in which to respond to or settle the
Dispute. Notice shall be sent to <legal@wasmer.io>. Both the Customer and
Wasmer agree that this dispute resolution process is a condition precedent that
must be satisfied prior to initiating any arbitration or filing any claim
against the other party.

### 15.2. Binding Arbitration

If 15.1 does not resolve the concern, the Customer and Wasmer agree that every
dispute arising in connection with these Terms will be resolved by binding
arbitration. This agreement to arbitrate disputes includes all claims arising
out of or relating to any aspect of these Terms, whether based in contract,
tort, statute, fraud, misrepresentation, or any other legal theory, and
regardless of whether a claim arises during or after the termination of these
Terms. The customer understands and agree that, by entering into these terms,
the Customer and Wasmer are each waiving the right to a trial by jury or to
participate in a class action.

### 15.3. ACICA Arbitration

Any dispute, controversy or claim arising out of, relating to or in connection
with this agreement, including any question regarding its existence, validity or
termination, shall be resolved by arbitration in accordance with the ACICA
Arbitration Rules. The seat of arbitration shall be Sydney, Australia. The
language of the arbitration shall be English. The number of arbitrators shall be
one.

 

16. Miscellaneous.
------------------

### 16.1. Force Majeure.

Neither party will be liable for failure or delay in performance to the extent
caused by circumstances beyond its reasonable control.

### 16.2. No Agency.

This Agreement does not create any agency, partnership or joint venture between
the parties.

### 16.3. Severability.

If any term (or part of a term) of this Agreement is invalid, illegal, or
unenforceable, the rest of the Agreement will remain in effect.

### 16.4. No Third-Party Beneficiaries.

This Agreement does not confer any benefits on any third party unless it
expressly states that it does.

### 16.5. Amendments.

Except as set forth in Section 1.7(b) or (c), any amendment must be in writing,
signed by both parties, and expressly state that it is amending this Agreement.

### 16.6. Entire Agreement.

This Agreement sets out all terms agreed between the parties and supersedes all
other agreements between the parties relating to its subject matter. In entering
into this Agreement, neither party has relied on, and neither party will have
any right or remedy based on, any statement, representation or warranty (whether
made negligently or innocently), except those expressly set out in this
Agreement. The terms located at a URL referenced in this Agreement and the
Documentation are incorporated by reference into the Agreement. After the
Effective Date, Wasmer may provide an updated URL in place of any URL in this
Agreement.

 

17. General Provisions
----------------------

### 17.1. Maintenance.

To ensure optimal performance and security of the Wasmer Platform, Wasmer
performs periodic Maintenance. In most cases, Maintenance will have limited or
no negative impact on the availability and functionality of the Wasmer Platform.
If Wasmer expects planned Maintenance to negatively affect the availability or
functionality of the Platform, Wasmer will use commercially reasonable efforts
to provide at least 7 days advance notice of the Maintenance. In addition,
Wasmer may perform emergency unscheduled Maintenance at any time. If Wasmer
expects such emergency unscheduled Maintenance to negatively affect the
availability or functionality of the Services, Wasmer will use commercially
reasonable efforts to provide advance notice of such Maintenance. Maintenance
notices noted above will be provided via the Wasmer Support Center.

### 17.2. Language Support.

The parties agree that all support provided by Wasmer pursuant to these
Guidelines will be provided in the English language.

 

18. Wasmer Platform Acceptable Use Policy.
------------------------------------------

Use of the Wasmer Platform is subject to this Acceptable Use Policy.

Customer agrees not to, and not to allow third parties to use the Wasmer
Platform:

-   to violate, or encourage the violation of, the legal rights of others (for
    example, this may include allowing Customer End Users to infringe on
    misappropriate the intellectual property rights of others in violation of
    the Digital Millennium Copyright Act);

    -   to intentionally distribute viruses, worms, Trojan horses, corrupted
        files, hoaxes, or other items of a destructive or deceptive nature;

    -   to transmit, distribute, post, store, link, or otherwise traffic in
        Content, information, software, or materials on or through the Service
        that is unlawful, threatening, abusive, harassing, defamatory, libelous,
        deceptive, fraudulent, invasive of another's privacy, tortious,
        offensive, profane, contains or depicts pornography that is unlawful, or
        is otherwise inappropriate as determined by Wasmer's sole discretion;

    -   to post unauthorized or unsolicited advertising;

    -   to impersonate any person or entity, including any of our employees or
        representatives, or includes anyone's identification documents or
        sensitive financial information;

    -   to take any action that imposes or may impose (as determined by Wasmer's
        sole discretion) an unreasonable or disproportionately large load on
        Wasmer's or a related 3rd Party's infrastructure;

    -   to interfere or attempt to interfere with the proper working of the
        Wasmer Platform or any activities conducted on the Wasmer Platform;

    -   to bypass, circumvent or attempt to bypass or circumvent any measures
        Wasmer may use to prevent or restrict access to the Wasmer Platform (or
        other accounts, computer systems or networks on the Wasmer Platform);

    -   to run any form of auto-responder or "spam" on the Wasmer Platform to
        generate, distribute, publish or facilitate unsolicited mass email,
        promotions, advertising or other solicitation;

    -   to use manual or automated software, devices, or other processes to
        "crawl" or "spider" any page of the Wasmer web-site;

    -   for high risk activities including but not limited to the operation of
        nuclear facilities, air traffic control, life support systems, or any
        other use where the failure of service could lead to death, personal
        injury, or environmental damage; or

    -   to decipher, decompile, disassemble, reverse engineer or otherwise
        attempt to derive any source code or underlying ideas or algorithms of
        any part of the Wasmer Platform (including without limitation any
        application), except to the limited extent applicable laws specifically
        prohibit such restriction,

    -   to modify, translate, or otherwise create derivative works of any part
        of the Wasmer Platform, or

    -   to copy, rent, lease, distribute, or otherwise transfer any of the
        rights that the Customer receive. The Customer shall abide by all
        applicable local, state, national and international laws and
        regulations;

    -   to obtain information about or make decisions about anyone but yourself.

19. Wasmer Technical Support Services Guidelines
------------------------------------------------

### 19.1 Support Request Submission

**19.1.1.** Customer Efforts to Fix Errors. Prior to making a request to Wasmer,
the Customer will use reasonable efforts to fix any error, bug, malfunction or
network connectivity defect without escalation to Wasmer. Thereafter, the
Customer may submit a written request for technical support through the Wasmer
Support Center.

**19.1.2.** Characterization of Requests. Customer designates priority upon
submission of Requests. Upon receiving a request, Wasmer will determine whether
the request is a "Platform Unusable," "Standard Request" or a "Feature Request."
Any such determination made by Wasmer is final and binding on Customer. Wasmer
reserves the right to change priority designation if Wasmer believes that
Customer's designation is incorrect and will inform the Customer of any such
change in its response to the support Request.

**19.1.3.** Procedures for Acknowledgment and Resolution of Requests. When
making a Request, the Customer will provide all requested diagnostic information
and assist Wasmer's Support Personnel in resolving the request.

**19.1.4.** Request Acknowledgment. Wasmer may respond to a Request by
acknowledging receipt of the Request. Customer acknowledges and understands that
Wasmer may be unable to provide answers to, or resolve all, Requests.

**19.1.5.** Feature Requests. If Wasmer deems a Request to be a Feature Request,
Wasmer will log such Request for consideration to add to a future update or
release of the Wasmer Platform and will consider the matter closed. Wasmer is
under no obligation to respond to or resolve any Feature Request or to include
any such Feature Request in any future update or release.

### 19.2. Accessing Support

**19.2.1.** Designated Support Contacts. Customer will provide first-level
support to Customer End Users. Wasmer will provide second-level support to
Customer only.

**19.2.2.** Support Hours and Target Initial Response Times. Wasmer will process
Requests on a best efforts basis. Wasmer does not provide any guarantee on
resolution times or response times.

###
`;

export const DISCORD_INVITE = "https://discord.gg/rWkMNStrEW";
export const DISCORD_EDGE_ALPHA_INVITE = "https://discord.gg/wht5d5aTzK";
export const YOUTUBE_EDGE_GET_STARTED_VIDEO_URL = "https://www.youtube.com";
export const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY!;
export const WASMER_GITHUB_REPO_URL = "https://github.com/wasmerio/wasmer";
export const WASMER_PYTHON_GITHUB_REPO_URL = "https://github.com/wasmerio/wasmer-python";
export const WASMER_JS_GITHUB_REPO_URL = "https://github.com/wasmerio/wasmer-js";
export const WASMER_GO_GITHUB_REPO_URL = "https://github.com/wasmerio/wasmer-go";
export const WASMER_RUBY_GITHUB_REPO_URL = "https://github.com/wasmerio/wasmer-ruby";
export const WASMER_C_API_URL = "https://docs.rs/wasmer-c-api/*/wasmer/wasm_c_api/index.html";
export const RUNTIME_GETSTARTED_DOCS_URL = "https://docs.wasmer.io/runtime/get-started";
export const REGISTRY_GETSTARTED_DOCS_URL = "https://docs.wasmer.io/registry/get-started";
export const EDGE_GETSTARTED_DOCS_URL = "https://docs.wasmer.io/edge/get-started";
export const RUNTIME_DOCS_URL = "https://docs.wasmer.io/runtime";
export const REGISTRY_DOCS_URL = "https://docs.wasmer.io/registry";
export const EDGE_DOCS_URL = "https://docs.wasmer.io/edge";
export const WASMER_DOCS_URL = "https://docs.wasmer.io/";
export const EDGE_CLI_DOCS_URL = "https://docs.wasmer.io/edge/cli";
