import Image from "next/image";
import Link from "next/link";
import React, { FC, Fragment, useEffect, useState } from "react";

// @ts-ignore
import cli from "@assets/icons/cli.svg?url";
// @ts-ignore
import leftArrow from "@assets/icons/leftArrow.svg?url";
// @ts-ignore
import profile from "@assets/icons/profile.svg?url";
import Button from "@components/shared/Buttons/Button";
import { Dialog, Menu, Transition } from "@headlessui/react";

import { ProductList } from "./NavbarItems";
import { GetStartedList, GuidesList, SdkList } from ".";

// import useAuth from '@/hooks/useAuth';

type Props = {
  open: boolean;
  onClose: (closed: boolean) => void;
  showApps: boolean;
  searchRender: React.ReactNode;
};

const MobileMenu: FC<Props> = ({ open, onClose, searchRender, showApps }) => {
  // const { isLogin, logout } = useAuth();
  const isLogin = false;
  const logout = () => { };
  const [isMenuOpen, setMenuOpen] = useState<null | "products" | "publish" | "sdk">(null);

  useEffect(() => {
    if (!open) {
      setMenuOpen(null);
    }
  }, [open]);

  const ProductSubMenu = () => (
    <Menu>
      <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
        <Image
          src={leftArrow}
          alt="arrow"
          width={6}
          height={11}
          className="mr-2 rotate-180 cursor-pointer"
          onClick={() => setMenuOpen(null)}
        />{" "}
        Products
      </div>
      {ProductList?.map((link) => (
        <Menu.Item key={link?.title} as={Fragment}>
          <Link href={link?.href} className="flex py-3">
            <div className="bg-dark-black flex h-[52px] w-[52px] items-center justify-center rounded-full">
              <Image src={link?.icon} alt="products" width={34} height={34} />
            </div>
            <div className="pl-3 sm:pl-6">
              <h6 className="font-semibold">{link?.title}</h6>
              <p className="text-dark-gray mt-1 font-medium">{link?.desc}</p>
            </div>
          </Link>
        </Menu.Item>
      ))}
      {isLogin && <SignoutRender />}
    </Menu>
  );

  const DeveloperSubMenu = () => (
    <Menu>
      <Menu.Item as={Fragment}>
        <div>
          <div>
            <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
              <Image
                src={leftArrow}
                alt="arrow"
                width={6}
                height={11}
                className="mr-2 rotate-180 cursor-pointer"
                onClick={() => setMenuOpen(null)}
              />{" "}
              Developers
            </div>
            <div className="lg:flex">
              <div className="w-[130px]">
                <p className="text-dark-gray whitespace-nowrap pb-3 font-medium">Learn more</p>
                {GetStartedList?.map((document, index) => (
                  <Link href={document.href} className="block pb-3 font-semibold" key={index}>
                    {document.title}
                  </Link>
                ))}
              </div>
              <div>
                <p className="text-dark-gray font-medium">Get Started</p>
                {GuidesList?.map((guides, index) => (
                  <Link href={guides?.href} key={index} className="block pt-3 font-semibold">
                    {guides?.guide}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          {isLogin && <SignoutRender />}
        </div>
      </Menu.Item>
    </Menu>
  );

  const SdkSubMenu = () => (
    <Menu>
      <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
        <Image
          src={leftArrow}
          alt="arrow"
          width={6}
          height={11}
          className="mr-2 rotate-180 cursor-pointer"
          onClick={() => setMenuOpen(null)}
        />{" "}
        SDK&apos;S
      </div>
      <div className="flex flex-wrap items-center gap-3">
        {SdkList?.map((sdk, index) => (
          <Menu.Item key={index} as={Fragment}>
            <Link href={sdk?.href}>
              <h5 className="font-semibold">{sdk?.title}</h5>
            </Link>
          </Menu.Item>
        ))}
      </div>
      {isLogin && <SignoutRender />}
    </Menu>
  );

  const SignoutRender = () => (
    <div className="mt-4 flex items-center justify-between border-t border-[#E5E5E8] pt-5">
      <div className="flex items-center">
        <Image src={profile} alt="profile" />
        <p className="text-dark-gray ml-3 text-new-base font-medium">Julian</p>
      </div>
      <Button classname="font-zeitung ml-2" title="Sign out" onClick={logout} />
    </div>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed top-14 left-4 right-4 z-[99] overflow-hidden" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="" />
          </Transition.Child>
          <div className="fixed top-14 left-4 right-4 flex max-w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="mt-2 flex h-full w-full flex-col overflow-y-auto rounded-xl border border-[#cecdd2] bg-[#FBFBFC] shadow-xl">
                <div className="p-6">
                  {isMenuOpen == "products" ? (
                    <ProductSubMenu />
                  ) : isMenuOpen == "publish" ? (
                    <DeveloperSubMenu />
                  ) : isMenuOpen == "sdk" ? (
                    <SdkSubMenu />
                  ) : (
                    <div>
                      <div className="py-7 lg:hidden">{searchRender}</div>
                      {showApps && (
                        <Link
                          href="/apps"
                          className="mb-3 flex items-center border-b border-[#E5E5E8] pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                        >
                          Apps
                        </Link>
                      )}
                      <div className="relative">
                        <Menu>
                          <Menu.Button
                            onClick={() => setMenuOpen("products")}
                            className="flex w-full items-center justify-between pb-3 text-new-base font-semibold lg:mr-6 lg:pb-0 lg:text-[#7f7b87]"
                          >
                            Products <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
                          </Menu.Button>
                        </Menu>
                      </div>
                      <div className="relative">
                        <Menu>
                          <Menu.Button
                            className="flex w-full items-center justify-between pb-3 text-new-base font-semibold lg:mr-6 lg:pb-0 lg:text-[#7f7b87]"
                            onClick={() => setMenuOpen("publish")}
                          >
                            Developers <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
                          </Menu.Button>
                        </Menu>
                      </div>
                      {/* <Link
                        href="/explore"
                        className="text-new-base font-semibold lg:text-[#7f7b87] block lg:flex pb-3 lg:pb-0 lg:mr-6"
                      >
                        Packages
                      </Link> */}
                      <Link
                        href="/posts"
                        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                      >
                        Blog
                      </Link>
                      <Link
                        href="/templates"
                        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
                      >
                        Templates
                      </Link>
                      <div className="relative lg:hidden">
                        <Menu>
                          <Menu.Button
                            className="flex w-full items-center justify-between pb-3 text-new-base font-semibold lg:mr-6 lg:pb-0 lg:text-[#7f7b87]"
                            onClick={() => setMenuOpen("sdk")}
                          >
                            SDK&apos;S <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
                          </Menu.Button>
                        </Menu>
                      </div>
                      <div className="mobile-menu-button lg:hidden">
                        <Link
                          href="https://docs.wasmer.io/runtime/cli"
                          className="flex items-center border-y border-[#E5E5E8] py-5"
                        >
                          <Image src={cli} alt="book" width={32} height={32} />
                          <h6 className="ml-3 text-new-base font-semibold">CLI</h6>
                        </Link>
                        <p className="text-dark-gray pt-5 pb-2">Tools</p>
                        <Link href="https://marketplace.visualstudio.com/items?itemName=dtsvet.vscode-wasm">
                          <h6 className="font-semibold">Visual Studio Code Extension</h6>
                        </Link>
                        {isLogin && <SignoutRender />}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <ProductSubMenu /> */}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenu;
