import Image from "next/image";
import Link from "next/link";
import React, { Fragment } from "react";

// import useAuth from "@/hooks/useAuth";
// @ts-ignore
// @ts-ignore
import cli from "@assets/icons/cli.svg?url";
// @ts-ignore
import deploy from "@assets/icons/deploy.svg?url";
// @ts-ignore
import leftArrow from "@assets/icons/leftArrow.svg?url";
// @ts-ignore
import publish from "@assets/icons/publish.svg?url";
// @ts-ignore
import run from "@assets/icons/run.svg?url";
import DevelopersNavbarItemHoverContent from "@components/DevelopersNavbarItemHoverContent";
import Button from "@components/shared/Buttons/Button";
import { Menu, Transition } from "@headlessui/react";

// @ts-ignore
import profile from "../../assets/icons/profile.svg?url";
import { SdkList } from ".";

export const ProductList = [
  {
    href: "/products/runtime",
    icon: run,
    title: "Runtime",
    desc: "Run any code anywhere",
  },
  {
    href: "/products/registry",
    icon: publish,
    title: "Registry",
    desc: "Create, publish, collaborate",
  },
  {
    href: "/products/edge",
    icon: deploy,
    title: "Edge",
    desc: "Deploy above the clouds",
  },
];

const SignoutRender = ({ logout }: { logout: () => void }) => (
  <div className="mt-4 flex items-center justify-between border-t border-[#E5E5E8] pt-5">
    <div className="flex items-center">
      <Image src={profile} alt="profile" />
      <p className="text-dark-gray ml-3 text-base font-medium">Julian</p>
    </div>
    <Button classname="font-zeitung ml-2" title="Sign out" onClick={logout} />
  </div>
);

interface NavbarItemsProps {
  viewer: any;
  showApps: boolean;
}

const NavbarItems: React.FC<NavbarItemsProps> = ({ viewer, showApps }) => {
  const isLogin = !!viewer?.username;
  const [_open, setOpen] = React.useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = React.useState<null | "products" | "publish" | "sdk">(null);
  const handleMouseEnter = (menu: null | "products" | "publish") => () => {
    setMenuOpen(menu);
  };
  const handleMouseLeave = (menu: null | "products" | "publish") => () => {
    setMenuOpen(menu);
  };

  return (
    <>
      {/* Apps Navbar item */}
      {showApps && (
        <Link
          href="/apps"
          className="block border-r border-[#C5C5C9] pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:pr-6 lg:text-[#7f7b87]"
          onClick={() => setOpen(false)}
        >
          Apps
        </Link>
      )}

      {/* Products Navbar item */}
      <div className="relative">
        <Menu>
          <Menu.Button
            onMouseEnter={handleMouseEnter("products")}
            onMouseLeave={handleMouseLeave(null)}
            className="flex w-full items-center justify-between pb-3 text-new-base font-semibold outline-none lg:mr-6 lg:pb-0 lg:text-[#7f7b87]"
          >
            Products <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
          </Menu.Button>
          <Transition
            onMouseEnter={handleMouseEnter("products")}
            onMouseLeave={handleMouseLeave(null)}
            show={isMenuOpen === "products"}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="lg:right-new-[initial] fixed top-0 right-0 left-0 z-20 rounded-xl border border-[#cecdd2] bg-[#FBFBFC] py-5 px-6 shadow-xl sm:px-8 lg:absolute lg:top-9 lg:left-2/4 lg:min-w-[347px] lg:-translate-x-[50%]">
              <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
                <Image src={leftArrow} alt="arrow" width={6} height={11} className="mr-2 rotate-180" /> Products
              </div>
              {ProductList?.map((link) => (
                <Menu.Item key={link?.title} as={Fragment}>
                  <Link href={link?.href} onClick={handleMouseLeave(null)} className="flex py-3">
                    <div className="bg-dark-black flex h-[52px] w-[52px] items-center justify-center rounded-full">
                      <Image src={link?.icon} alt="products" width={34} height={34} />
                    </div>
                    <div className="pl-3 sm:pl-6">
                      <h6 className="font-semibold">{link?.title}</h6>
                      <p className="text-dark-gray mt-1 font-medium">{link?.desc}</p>
                    </div>
                  </Link>
                </Menu.Item>
              ))}
              {/* {isLogin && (
                <div className="lg:hidden">
                  <SignoutRender {...{ logout: () => {} }} />
                </div>
              )} */}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {/* Developers Navbar item */}
      <div className="relative">
        <Menu>
          <Menu.Button
            onMouseEnter={handleMouseEnter("publish")}
            onMouseLeave={handleMouseLeave(null)}
            className="flex w-full items-center justify-between pb-3 text-new-base font-semibold outline-none lg:mr-6 lg:pb-0 lg:text-[#7f7b87]"
          >
            Developers <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
          </Menu.Button>

          <DevelopersNavbarItemHoverContent show={isMenuOpen === "publish"} />
        </Menu>
      </div>
      {/* Packages Navbar item */}
      {/* <Link
        href="/explore"
        className="text-new-base font-semibold lg:text-[#7f7b87] block lg:flex pb-3 lg:pb-0 lg:mr-6"
        onClick={() => setOpen(false)}
      >
        Packages
      </Link> */}

      {/* Blog Navbar item */}
      <Link
        href="/posts"
        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
        onClick={() => setOpen(false)}
      >
        Blog
      </Link>
      <Link
        href="/templates"
        className="block pb-3 text-new-base font-semibold lg:mr-6 lg:flex lg:pb-0 lg:text-[#7f7b87]"
        onClick={() => setOpen(false)}
      >
        Templates
      </Link>
      {/* SDK Navbar item */}
      <div className="relative lg:hidden">
        <Menu>
          <Menu.Button className="flex w-full items-center justify-between pb-3 text-new-base font-semibold outline-none lg:mr-6 lg:pb-0 lg:text-[#7f7b87]">
            SDK&apos;S <Image src={leftArrow} alt="arrow" width={6} height={11} className="lg:hidden" />
          </Menu.Button>
          <Transition
            show={isMenuOpen === "sdk"}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="lg:right-new-[initial] fixed top-0 right-0 left-0 z-20 max-h-[45vh] overflow-y-auto rounded-xl border border-[#cecdd2] bg-[#FBFBFC] py-5 px-6 shadow-xl sm:px-8 lg:absolute lg:top-9 lg:left-2/4 lg:min-w-[347px] lg:-translate-x-[50%]">
              <div className="mb-4 flex items-center border-b border-[#E5E5E8] pb-4 font-semibold lg:hidden">
                <Image src={leftArrow} alt="arrow" width={6} height={11} className="mr-2 rotate-180" /> SDK&apos;S
              </div>
              <div className="flex flex-wrap items-center gap-3">
                {SdkList?.map((sdk, index) => (
                  <Menu.Item key={index} as={Fragment}>
                    <Link href={sdk?.href}>
                      <h5 className="font-semibold">{sdk?.title}</h5>
                    </Link>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {/* CLI Navbar item */}
      <div className="lg:hidden">
        <Link href="#" className="flex items-center border-y border-[#E5E5E8] py-5">
          <Image src={cli} alt="book" width={32} height={32} />
          <h6 className="ml-3 text-new-base font-semibold">CLI</h6>
        </Link>
        <p className="text-dark-gray pt-5 pb-2">Tools</p>
        <Link href="https://marketplace.visualstudio.com/items?itemName=dtsvet.vscode-wasm">
          <h6 className="font-semibold">Visual Studio Code Extension</h6>
        </Link>
        {!isLogin ? null : <SignoutRender logout={() => { }} />}
      </div>
    </>
  );
};

export default NavbarItems;
