import { useRouter, useSearchParams } from "next/navigation";
import React, { FormEvent, memo } from "react";

import SearchMagnifier from "@assets/SearchMagnifier";
import { Combobox } from "@headlessui/react";

interface SearchInputProps {
  handleSearch: (searchQuery: string) => void;
  handleSearchInputFocus: () => void;
  handleSearchInputBlur: () => void;
}

export enum SearchInputValueType {
  search = "search",
  link = "link",
}

export interface SearchInputValue {
  type: SearchInputValueType;
  value: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ handleSearch, handleSearchInputBlur, handleSearchInputFocus }) => {
  const router = useRouter(),
    searchParams = useSearchParams();
  const searchQuery = searchParams.get("q");

  const [inputDisplayValue, setInputDisplayValue] = React.useState<SearchInputValue | null>({
    value: searchQuery || "",
    type: SearchInputValueType.search,
  });

  const handleInputChange = React.useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement).value;
      setInputDisplayValue({ value, type: SearchInputValueType.search });
      handleSearch(value);
    },
    [handleSearch, setInputDisplayValue]
  );
  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        const searchInput = inputDisplayValue;

        const inputType = searchInput?.type!,
          inputValue = searchInput?.value!;
        if (inputType === SearchInputValueType.link) {
          router.push(`/${inputValue}`);
        } else if (inputType === SearchInputValueType.search) {
          router.push(`/search?q=${inputValue}`);
        }
        (event.target as HTMLInputElement).blur();
      }
      if (event.key === "Escape") {
        (event.target as HTMLInputElement).blur();
      }
    },
    [inputDisplayValue, router]
  );

  return (
    <Combobox.Label className="flex w-full items-center justify-center gap-[6px] rounded-[4px] bg-[#0F0518]/5 px-[16px] py-[6px] group-focus-within/HeaderSearch:gap-[12px] group-focus-within/HeaderSearch:py-[12px]">
      <SearchMagnifier className="h-auto w-[14px] min-w-[14px] opacity-50 group-focus-within/HeaderSearch:w-[18px] group-focus-within/HeaderSearch:min-w-[18px]" />
      <Combobox.Input
        value={inputDisplayValue?.value}
        className="w-full max-w-[80vw] text-[16px] font-[400] tracking-[0.32px] transition-all duration-[1000ms] ease-in-out focus:w-[400px] focus:border-transparent focus:outline-none focus:ring-0 focus:ring-transparent"
        placeholder="Search..."
        onChange={handleInputChange}
        onFocus={handleSearchInputFocus}
        onBlur={handleSearchInputBlur}
        onKeyUp={handleKeyUp}
      />
    </Combobox.Label>
  );
};

export default memo(SearchInput);
