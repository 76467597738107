import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";
import { graphql, useFragment } from "react-relay";
import { twMerge } from "tailwind-merge";

import PackageNew from "@assets/PackageNew";
import { UserSearchItem_details$key } from "@generated/UserSearchItem_details.graphql";
import { Combobox } from "@headlessui/react";

import SearchCounterIcon from "./SearchCounterIcon";
import { SearchInputValueType } from "./SearchInput";

const UserSearchItemFragment = graphql`
  fragment UserSearchItem_details on User {
    username
    fullName
    avatar(size: 30)
    packages {
      totalCount
    }
  }
`;

type UserSearchItemProps = {
  fragmentRef: UserSearchItem_details$key;
  className?: string;
};

const UserSearchItem: React.FC<UserSearchItemProps> = ({ fragmentRef, className }) => {
  const { username, avatar, fullName, packages } = useFragment(UserSearchItemFragment, fragmentRef);
  const router = useRouter();
  const handleRedirection = () => {
    router.push(`/${username}`);
  };
  const optionValue = {
      type: SearchInputValueType.link,
      value: username,
    },
    packagesTotalCount = packages?.totalCount;
  return (
    <Combobox.Option
      value={optionValue}
      className={twMerge(clsx("flex w-full items-center justify-center gap-3", className))}
      onClick={handleRedirection}
    >
      <Image
        src={avatar}
        alt="package icon"
        width={40}
        height={40}
        className="col-start-1 row-start-1 h-[40px] w-[40px] rounded-full border border-[#0F0518]/20"
        crossOrigin="anonymous"
      />
      <div className="flex w-full flex-1 items-center justify-between">
        <div className="flex flex-col">
          <h6 className="flex items-center justify-start gap-[3px] text-[16px] font-semibold text-[#0F0518]">
            {fullName}
          </h6>
          <p className="text-[13px] font-[400] text-[#0F0518]/50">{username}</p>
        </div>
        <SearchCounterIcon
          icon={<PackageNew className="hidden h-[22px] w-[22px] md:block" />}
          count={packagesTotalCount || 0}
        />
      </div>
    </Combobox.Option>
  );
};

export default UserSearchItem;
