import React from "react";
import { graphql, useFragment, useLazyLoadQuery, useRefetchableFragment } from "react-relay";

import { SearchResultsQuery } from "@generated/SearchResultsQuery.graphql";
import { SearchResults_autocompleteData$key } from "@generated/SearchResults_autocompleteData.graphql";
import { SearchResults_autocompleteDataRefetchQuery } from "@generated/SearchResults_autocompleteDataRefetchQuery.graphql";
import { SearchResults_data$data, SearchResults_data$key } from "@generated/SearchResults_data.graphql";
import { Combobox, Transition } from "@headlessui/react";
import { cn } from "@libs/helper";

import NamespaceSearchItem from "./NamespaceSearchItem";
import PackageSearchItem from "./PackageSearchItem";
import ResultSection from "./ResultSection";
import { SearchInputValueType } from "./SearchInput";
import UserSearchItem from "./UserSearchItem";

export enum SearchResultType {
  Namespace = "Namespace",
  PackageVersion = "PackageVersion",
  User = "User",
}

const SearchResults_Query = graphql`
  query SearchResultsQuery($query: String!) {
    ...SearchResults_autocompleteData
  }
`;

const SearchResults_autocompleteDataFragment = graphql`
  fragment SearchResults_autocompleteData on Query
  @refetchable(queryName: "SearchResults_autocompleteDataRefetchQuery") {
    searchAutocomplete(query: $query, first: 8) {
      edges {
        node {
          __typename
          ...SearchResults_data
        }
      }
    }
  }
`;

const SearchResultFragment = graphql`
  fragment SearchResults_data on SearchResult @relay(plural: true) {
    __typename
    ...UserSearchItem_details
    ...PackageSearchItem_details
    ...NamespaceSearchItem_details
    ...DeployAppSearchItem_details
  }
`;

interface SearchResultsProps {
  isSearchInputFocused: boolean;
  debouncedSearchQueryInputValue: string;
}
const SearchResults: React.FC<SearchResultsProps> = ({ debouncedSearchQueryInputValue, isSearchInputFocused }) => {
  const searchQueryData = useLazyLoadQuery<SearchResultsQuery>(SearchResults_Query, {
    query: "",
  });
  const [searchResultsAutocompletedata, refetch] = useRefetchableFragment<
    SearchResults_autocompleteDataRefetchQuery,
    SearchResults_autocompleteData$key
  >(SearchResults_autocompleteDataFragment, searchQueryData);
  const handleRefetch = React.useCallback(() => {
    refetch({ query: debouncedSearchQueryInputValue });
  }, [refetch, debouncedSearchQueryInputValue]);
  const filteredResults = searchResultsAutocompletedata?.searchAutocomplete?.edges
    ?.map((edge) => edge?.node!)
    ?.filter((node) => !!node)!;

  const results = useFragment<SearchResults_data$key>(SearchResultFragment, filteredResults);

  const groupedResults: {
    [key in SearchResultType]: SearchResults_data$data;
  } = results?.reduce((result: any, item) => {
    result[(item as any)?.__typename] = result[(item as any)?.__typename] || [];
    result[(item as any)?.__typename].push(item);

    return result;
  }, {});
  const noResults = results?.length === 0;

  const searchOptionValue = {
    type: SearchInputValueType.search,
    value: debouncedSearchQueryInputValue,
  };

  React.useEffect(() => {
    handleRefetch();
  }, [handleRefetch]);

  return (
    <Transition
      show={isSearchInputFocused}
      as="div"
      className={cn(
        "absolute top-[52px] w-full overflow-hidden rounded-[12px] border border-t-0 border-[#0F0518]/20 bg-[#FBFBFC]",
        noResults && "border-0"
      )}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0 top-0"
      enterTo="opacity-100 top-10"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100 top-10"
      leaveTo="opacity-0 top-0"
    >
      <Combobox.Options className="max-h-[calc(80vh-4rem)] w-full overflow-auto">
        <Combobox.Option id="search-query-input-value" value={searchOptionValue} className="hidden" />
        {groupedResults?.PackageVersion?.length > 0 ? (
          <ResultSection searchQuery={debouncedSearchQueryInputValue} type={SearchResultType.PackageVersion}>
            {groupedResults?.PackageVersion?.map((result) => (
              <PackageSearchItem
                key={(result as any).__id}
                className="cursor-pointer px-5 py-[10px] ui-active:bg-[#0F0518]/5"
                fragmentRef={result}
              />
            ))}
          </ResultSection>
        ) : null}
        {groupedResults?.User?.length > 0 ? (
          <ResultSection searchQuery={debouncedSearchQueryInputValue} type={SearchResultType.User}>
            {groupedResults?.User?.map((result) => (
              <UserSearchItem
                key={(result as any).__id}
                fragmentRef={result}
                className="cursor-pointer px-5 py-[10px] ui-active:bg-[#0F0518]/5"
              />
            ))}
          </ResultSection>
        ) : null}
        {groupedResults?.Namespace?.length > 0 ? (
          <ResultSection searchQuery={debouncedSearchQueryInputValue} type={SearchResultType.Namespace}>
            {groupedResults?.Namespace?.map((result) => (
              <NamespaceSearchItem
                key={(result as any).__id}
                fragmentRef={result}
                className="cursor-pointer px-5 py-[10px] ui-active:bg-[#0F0518]/5"
              />
            ))}
          </ResultSection>
        ) : null}
      </Combobox.Options>
    </Transition>
  );
};

export default SearchResults;
