import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Collaborators = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#0F0518]", props?.className))}
    >
      <path
        d="M9.23242 15.7502V21.2502H21.2324V15.7502C21.2324 13.5411 19.4416 11.7502 17.2324 11.7502H13.2324C11.0233 11.7502 9.23242 13.5411 9.23242 15.7502Z"
        stroke="#0F0518"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.73242 21.2502H1.23242V15.7502C1.23242 13.5411 3.02328 11.7502 5.23242 11.7502H6.23242"
        stroke="#0F0518"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15.2324"
        cy="4.37524"
        r="3.625"
        stroke="#0F0518"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="6.23242"
        cy="5.00024"
        r="3"
        stroke="#0F0518"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Collaborators;
