import Image from "next/image";
import React from "react";
import { graphql, useFragment } from "react-relay";

import GradientFolder from "@assets/GradientFolder";
import { NamespaceIcon_data$key } from "@generated/NamespaceIcon_data.graphql";

const NamespaceIcon_dataFragment = graphql`
  fragment NamespaceIcon_data on Namespace {
    avatar
    displayName
  }
`;

interface NamespaceIconProps {
  fragmentRef: NamespaceIcon_data$key;
}
const NamespaceIcon: React.FC<NamespaceIconProps> = ({ fragmentRef }) => {
  const data = useFragment(NamespaceIcon_dataFragment, fragmentRef);
  return (
    <div className="z-10 grid min-w-max	grid-cols-1 grid-rows-1 place-items-center p-[2.5px]">
      <div className="z-1 col-start-1 row-start-1 mr-[4px] -mt-[2px] h-[27px] w-[27px] origin-bottom rotate-[15deg] rounded-[4.8px] bg-gradient-to-b from-[#4AB3FF] to-[#4AB3FF]/0" />
      <Image
        src={data.avatar}
        alt={`${data.displayName} avatar`}
        width={27}
        height={27}
        className="z-[2] col-start-1 row-start-1 h-auto w-[27px] min-w-[27px] rounded-[4.8px]"
        crossOrigin="anonymous"
      />
      <GradientFolder className="z-[3] col-start-1 row-start-1 -mb-[10px] h-auto w-[32px] min-w-[32px] self-end" />
    </div>
  );
};

export default NamespaceIcon;
