import Link from "next/link";
import React from "react";

import { SearchResultType } from ".";

interface ResultSectionProps {
  type: SearchResultType;
  children: React.ReactNode;
  searchQuery: string;
}
const ResultSection: React.FC<ResultSectionProps> = ({ type, children, searchQuery }) => {
  const sectionName = {
    [SearchResultType.Namespace]: "Namespaces",
    [SearchResultType.PackageVersion]: "Packages",
    [SearchResultType.User]: "Users",
    [SearchResultType.DeployApp]: "Apps",
  }[type];
  const readMoreLink = {
    [SearchResultType.Namespace]: `/search?q=${searchQuery}&type=namespace`,
    [SearchResultType.PackageVersion]: `/search?q=${searchQuery}&type=package`,
    [SearchResultType.User]: `/search?q=${searchQuery}&type=user`,
    [SearchResultType.DeployApp]: `/search?q=${searchQuery}&type=app`,
  }[type];
  return (
    <div className="flex w-full flex-col items-center justify-center border-t border-[#0F0518]/20 py-[10px]">
      <div className="flex w-full items-center justify-between px-5 py-[10px]">
        <p className="text-right text-[16px] font-[600] leading-[140%] text-[#0F0518] opacity-50">{sectionName}</p>
        <Link href={readMoreLink}>
          <p className="text-[13px] font-[600] leading-[130%] text-[#605DE9] hover:underline">Show more</p>
        </Link>
      </div>
      <div className="flex h-full w-full flex-col items-center justify-center">{children}</div>
    </div>
  );
};

export default ResultSection;
