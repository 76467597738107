"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";
import bgGetStarted from "@assets/images/bgGetStarted.webp";
import SubScribeNewsletter from "@components/SubScribeNewsletter";
import { DISCORD_INVITE } from "@utils/constants";

// import vercel from "../../assets/images/vercel.png";

const subscribeRoute = ["/posts", "/posts/[slug]"];

const GetStartedCode = () => {
  const pathname = usePathname();
  const [currentRoute, setCurrentRoute] = useState(pathname ?? "/");

  useEffect(() => {
    setCurrentRoute(pathname);
  }, [pathname, setCurrentRoute]);

  const subscribeRender = () => subscribeRoute.includes(currentRoute);

  return (
    <>
      <div className="pb-16">
        {subscribeRender() ? (
          <SubScribeNewsletter />
        ) : (
          <div>
            {/* <h6 className="pt-[85px] pb-6 text-center text-new-xl lg:text-[22px] font-medium">
              We are trusted by the best
            </h6>
            <div className="relative overflow-hidden text-center xl:overflow-visible">
              <p className="text-dark-gray mx-auto mb-6 max-w-[567px] px-[15px] text-center text-new-xl font-normal lg:text-[22px]">
                “This programming tool makes it easier for apps to work anywhere”
              </p>
              <Image src={vercel} alt="vercel" className="mx-auto mb-[96px]" />
            </div> */}
          </div>
        )}
        <div className="relative z-[1] text-center">
          <div className="inhiritmidca relative mx-4 max-w-[1072px] md:mx-10 xlg:mx-auto">
            <div className="bg-dark-black rounded-xl p-6 text-left md:pb-6 md:pl-14 md:pr-6 md:pt-14 lg:pb-8 lg:pl-16 lg:pr-8 lg:pt-16">
              <Link href={DISCORD_INVITE} target="_blank">
                <h4 className="text-light-white max-w-max text-4xl font-medium hover:underline md:text-[40px] lg:text-5xl">
                  Get Started
                </h4>
              </Link>
              <p className="mb-14 mt-7 text-new-xl font-normal text-[#B4B1B8] sm:mb-16 md:mr-8 lg:text-[22px]">
                Join the vibrant Wasmer community. Create your first package, run it from the terminal or in your
                favorite programming language.
                {/* and deploy it to the Edge */}
              </p>
              <div className="text-right">
                <Link href={DISCORD_INVITE} target="_blank" className="inline-block">
                  <button className="group ml-auto flex h-[48px] w-[48px] min-w-[48px] items-center justify-center rounded-full bg-[#fbfbfc] sm:h-[54px] sm:w-[54px] sm:min-w-[54px]">
                    <span className="duration-200 group-hover:mb-1 group-hover:ml-1">
                      <ArrowOutwardIcon color="#000" width={12} height={12} />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <Image
            src={bgGetStarted}
            alt="gradient"
            className="absolute left-2/4 top-2/4 -z-[1] max-h-[450px] max-w-[110%] -translate-x-[50%] -translate-y-[50%] sm:h-auto xl:max-w-[1280px]"
          />
        </div>
      </div>
    </>
  );
};

export default GetStartedCode;
