import clsx from "clsx";
import React, { memo } from "react";

import { Combobox } from "@headlessui/react";
import useDebounce from "@hooks/useDebounce";
import { cn } from "@libs/helper";

import SearchInput from "./SearchInput";
import SearchResults from "./SearchResults";

export enum SearchResultType {
  Namespace = "Namespace",
  PackageVersion = "PackageVersion",
  User = "User",
  DeployApp = "DeployApp",
}

type HeaderSearchProps = {
  className?: string;
};

const HeaderSearch: React.FC<HeaderSearchProps> = ({ className }) => {
  const [isSearchInputFocused, setIsSearchInputFocused] = React.useState(false);
  const handleSearchInputFocus = React.useCallback(() => {
    setIsSearchInputFocused(true);
  }, [isSearchInputFocused]);
  const handleSearchInputBlur = React.useCallback(() => {
    setIsSearchInputFocused(false);
  }, [isSearchInputFocused]);

  const [searchQueryInputValue, setSearchQueryInputValue] = React.useState("");
  const handleSearch = React.useCallback(
    (searchQueryInputValue: string) => {
      setSearchQueryInputValue(searchQueryInputValue);
    },
    [setSearchQueryInputValue, isSearchInputFocused]
  );

  const debouncedSearchQueryInputValue = useDebounce<string>(searchQueryInputValue, 300);

  return (
    <>
      <div className="peer relative z-50 flex-1">
        <div className={cn("absolute top-1/2 -translate-y-1/2", className)}>
          {/* real content here */}
          <div
            className={clsx(
              "group/HeaderSearch top-0 flex w-full max-w-[80vw] flex-col items-start justify-start rounded-[4px] font-gilroy transition-all duration-[400ms] ease-in-out focus-within:rounded-[12px] focus-within:bg-[#FBFBFC]"
            )}
          >
            <Combobox>
              <SearchInput
                handleSearch={handleSearch}
                handleSearchInputFocus={handleSearchInputFocus}
                handleSearchInputBlur={handleSearchInputBlur}
              />
              <SearchResults
                isSearchInputFocused={isSearchInputFocused}
                debouncedSearchQueryInputValue={debouncedSearchQueryInputValue}
              />
            </Combobox>
          </div>
        </div>
      </div>
      {/* TODO: fix overlay on mobile devices */}
      <div
        className="invisible fixed inset-0 z-[6] bg-[#0F0518]/[15%] opacity-0 transition-all duration-700 ease-in-out peer-focus-within:visible peer-focus-within:opacity-100 tablet:h-screen"
        aria-hidden="true"
      />
    </>
  );
};

export default memo(HeaderSearch);
