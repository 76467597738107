import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const GradientFolder = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 23"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("opacity-[75%]", props?.className))}
    >
      <path
        d="M15.899 4.8a3.2 3.2 0 01-2.458-1.151L11.358 1.15A3.2 3.2 0 008.902 0h-5.7C1.432 0 0 1.43 0 3.197L0 17.604A4.796 4.796 0 004.8 22.4h22.4a4.8 4.8 0 004.8-4.8V8a3.2 3.2 0 00-3.2-3.2H15.9z"
        fill="#CDD5E9"
      />
    </svg>
  );
};

export default GradientFolder;
