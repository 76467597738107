import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const PackageNew = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#0F0518]", props?.className))}
    >
      <path
        d="M18.143 1H3.857A2.857 2.857 0 001 3.857v14.286A2.857 2.857 0 003.857 21h14.286A2.857 2.857 0 0021 18.143V3.857A2.857 2.857 0 0018.143 1zM12.572 15.71h3.143"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PackageNew;
