/**
 * @generated SignedSource<<0b4a641f3ce1d05ea208959350dd9856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NamespaceSearchItem_details$data = {
  readonly collaborators: {
    readonly totalCount: number | null | undefined;
  };
  readonly description: string;
  readonly name: string;
  readonly packages: {
    readonly totalCount: number | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NamespaceIcon_data">;
  readonly " $fragmentType": "NamespaceSearchItem_details";
};
export type NamespaceSearchItem_details$key = {
  readonly " $data"?: NamespaceSearchItem_details$data;
  readonly " $fragmentSpreads": FragmentRefs<"NamespaceSearchItem_details">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NamespaceSearchItem_details",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "NamespaceCollaboratorConnection",
      "kind": "LinkedField",
      "name": "collaborators",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageConnection",
      "kind": "LinkedField",
      "name": "packages",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NamespaceIcon_data"
    }
  ],
  "type": "Namespace",
  "abstractKey": null
};
})();

(node as any).hash = "5759b17797b8d20fd03a26d86ae7315d";

export default node;
