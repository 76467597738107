import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const SearchHeart = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("", props?.className))}
    >
      <g clipPath="url(#prefix__clip0_6_7425)">
        <path
          d="M11.223 20.5L2.71 11.374A5.256 5.256 0 011.298 8.53a5.34 5.34 0 01.467-3.158A5.163 5.163 0 013.288 3.51a4.964 4.964 0 012.18-.943 4.898 4.898 0 012.356.184 5.02 5.02 0 012.017 1.272l1.391 1.435 1.392-1.435a5.02 5.02 0 012.016-1.271 4.898 4.898 0 012.357-.185 4.964 4.964 0 012.18.943A5.164 5.164 0 0120.7 5.373a5.34 5.34 0 01.467 3.158 5.256 5.256 0 01-1.412 2.843c-2.744 2.942-8.532 9.126-8.532 9.126z"
          stroke="#0F0518"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_6_7425">
          <path fill="#fff" transform="translate(.232 .5)" d="M0 0h22v22H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchHeart;
