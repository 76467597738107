import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const SearchMagnifier = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("fill-[#0F0518]", props?.className))}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.807 7.592a5.749 5.749 0 1111.497 0 5.749 5.749 0 01-11.497 0zM7.555.344a7.249 7.249 0 104.566 12.878l4.29 4.29a.75.75 0 001.06-1.06l-4.289-4.29A7.249 7.249 0 007.555.344z"
      />
    </svg>
  );
};

export default SearchMagnifier;
