"use client";

import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useState } from "react";
import { graphql, useFragment } from "react-relay";

import DiscordLogo from "@assets/icons/DiscordLogo";
import GithubIcon from "@assets/icons/GithubIcon";
// @ts-ignore
import bar from "@assets/icons/bar.svg?url";
// @ts-ignore
import bell from "@assets/icons/bell.svg?url";
// @ts-ignore
import close from "@assets/icons/close.svg?url";
// @ts-ignore
import logo from "@assets/images/logo.svg?url";
import HeaderSearch from "@components/HeaderSearch";
import PageSection from "@components/PageCreateApp/PageSection";
import IconButton from "@components/shared/Buttons/IconButton";
import RegularButton from "@components/shared/Buttons/RegularButton";
import { HeaderNew_viewer$key } from "@generated/HeaderNew_viewer.graphql";
// import { HeaderSearchQuery } from "@generated/HeaderSearchQuery.graphql";
import {
  DISCORD_INVITE,
  EDGE_DOCS_URL,
  EDGE_GETSTARTED_DOCS_URL,
  REGISTRY_DOCS_URL,
  REGISTRY_GETSTARTED_DOCS_URL,
  RUNTIME_DOCS_URL,
  RUNTIME_GETSTARTED_DOCS_URL,
  WASMER_C_API_URL,
  WASMER_GITHUB_REPO_URL,
  WASMER_GO_GITHUB_REPO_URL,
  WASMER_JS_GITHUB_REPO_URL,
  WASMER_PYTHON_GITHUB_REPO_URL,
  WASMER_RUBY_GITHUB_REPO_URL,
} from "@utils/constants";

import NavbarItems from "./NavbarItems";
import styles from "./index.module.css";
import MobileMenu from "./mobileMenu";

const CustomBanner = dynamic(() => import("@components/Banner/CustomBanner"), {
  ssr: false,
});

export const GuidesList = [
  {
    href: RUNTIME_GETSTARTED_DOCS_URL,
    guide: "Run a package",
  },
  {
    href: REGISTRY_GETSTARTED_DOCS_URL,
    guide: "Publish a package",
  },
  {
    href: EDGE_GETSTARTED_DOCS_URL,
    guide: "Deploy your website",
  },
];

export const GetStartedList = [
  { title: "Runtime", href: RUNTIME_DOCS_URL },
  {
    title: "Registry",
    href: REGISTRY_DOCS_URL,
  },
  { title: "Edge", href: EDGE_DOCS_URL },
];

export const SdkList = [
  { href: WASMER_GITHUB_REPO_URL, title: "Rust" },
  { href: WASMER_PYTHON_GITHUB_REPO_URL, title: "Python" },
  { href: WASMER_JS_GITHUB_REPO_URL, title: "JS" },
  { href: WASMER_GO_GITHUB_REPO_URL, title: "Go" },
  { href: WASMER_RUBY_GITHUB_REPO_URL, title: "Ruby" },
  {
    href: WASMER_C_API_URL,
    title: "C",
  },
];

type Props = {
  viewer: HeaderNew_viewer$key | null;
  useDarkBackground?: boolean;
};

const Header: React.FC<Props> = (props) => {
  const viewer = useFragment(
    graphql`
      fragment HeaderNew_viewer on User {
        ...AuthenticatedNavbarUserAvatar_data
      }
    `,
    props.viewer
  );

  const [open, setOpen] = useState<boolean>(false);

  const pathname = usePathname();

  const isLogin = !!viewer,
    currentPath = pathname,
    loginPath = "/login?redirect=" + encodeURIComponent(currentPath),
    isHomePage = pathname === "/";

  // const
  return (
    <div
      id="header"
      className="bg-transparent-light sticky left-0 right-0 top-0 z-[99] backdrop-blur-md"
    >
      {/* <CustomBanner
        announcementKey="wasmer-edge-announcement"
        message="Wasmer Edge is now generally available"
        link="/posts/wasmer-edge-beta-is-ga"
      /> */}
      <PageSection
        classNameForWrapper={
          isHomePage ? undefined : styles.HeaderPageSectionContainer
        }
        className={styles.HeaderPageSection}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center gap-4 tablet:w-full">
            <Link href="/">
              <img src={logo.src} width={124} height={36} alt="logo" className="max-w-[unset] " />
            </Link>
            <HeaderSearch className="hidden tablet:block" key="header-search" />
          </div>
          <div className="flex items-center gap-[8px] ">
            <div className="hidden lg:flex">
              <NavbarItems viewer={viewer} showApps={isLogin} />
            </div>
            <div className=" hidden flex-row  items-center  gap-[8px] tablet:flex ">
              <Link href="https://github.com/wasmerio/wasmer">
                <IconButton
                  icon={
                    <div className="flex flex-row gap-[4px] ">
                      <GithubIcon height={18} width={18} />{" "}
                      <span className="font-gilroy text-[16px] font-medium ">
                        17k
                      </span>
                    </div>
                  }
                  className="flex items-center rounded-[20px] border border-card-stroke px-[8px] py-[4px] hover:bg-dark-grey-07 "
                />
              </Link>
              <Link href={DISCORD_INVITE}>
                <IconButton
                  icon={
                    <DiscordLogo
                      fill="#0F0518"
                      className="h-[18px] w-[18px] "
                    />
                  }
                  className="flex items-center rounded-[50%] border border-card-stroke p-[6px] hover:bg-dark-grey-07 "
                />
              </Link>
            </div>

            {/* <Link href={loginPath}> */}
            <RegularButton
              variant="secondary"
              text="Sign in"
              className={styles.signinBtn}
              link={loginPath}
              data-cy="header-signin-btn"
            />
            {/* <Button
                id="login-btn"
                classname="font-zeitung ml-2 flex whitespace-nowrap "
                title="Sign in"
              /> */}
            {/* </Link> */}
            {isLogin && (
              <img
                src={bell.src}
                alt="bell"
                className="ml-6 flex cursor-pointer lg:hidden"
              />
            )}
            {open ? (
              <img
                src={close.src}
                alt="close"
                width={16}
                className="ml-6 flex cursor-pointer lg:hidden"
                onClick={() => setOpen(false)}
              />
            ) : (
              <img
                src={bar.src}
                alt="menu"
                width={20}
                className="ml-6 flex cursor-pointer lg:hidden"
                onClick={() => setOpen(!open)}
              />
            )}
            <MobileMenu
              open={open}
              onClose={setOpen}
              showApps={isLogin}
              searchRender={<HeaderSearch key="header-search" />}
            />
          </div>
        </div>
      </PageSection>
    </div>
  );
};

export default Header;
